import consumer from "./consumer"

consumer.subscriptions.create("FinishedArticleGuestUserChannel", {
  connected() {
    console.log("Connected to FinishedArticleGuestUserChannel");
  },

  disconnected() {
    console.log("Disconnected from FinishedArticleGuestUserChannel");
  },

  received(data) {
    // let received_url_path = '/articles/' + data.content.id;
    // if (window.location.pathname == received_url_path) {
    //   location.reload();
    // }

    let update_article_form = $('#update_article_form');
    if (update_article_form.length > 0) {
      window.location.reload()
    }
  }
});